import React, { useState } from 'react'
import {
    TextField, Typography, Box, Grid, Button, Container, DialogContent,
    Select, MenuItem, InputLabel, FormControl,
    DialogActions, Dialog, Card, CardContent, Alert, DialogContentText, DialogTitle
} from "@mui/material";
import { db, firebase, functions } from '../../db/firebase'
import Swal from 'sweetalert2'
import InputValidation from '../../components/utils/InputValidation';
import fondo from "../../assets/img/bannerbscapp.png"
export const ActualizarDatosPage = () => {
    const [contractArray, setContractArray] = useState([]);
    const [open, setOpen] = useState(false);
    const [cedula, setCedula] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [nombre, setNombre] = useState({ campo: '', valido: null });
    const [correo, setCorreo] = useState({ campo: '', valido: null });
    const [telefono, setTelefono] = useState({ campo: '', valido: null });
    const [movil, setMovil] = useState({ campo: '', valido: null });
    const [direccionPrincipal, setDireccionPrincipal] = useState({ campo: '', valido: null });
    const [num_contrato, setNumContrato] = useState('');
    const [tab, setTab] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const getClient = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')
                        Swal.fire({
                            icon: 'info',
                            text: 'No se encontro cliente',
                        })
                        setCedula('')
                    } else {
                        const data = dataParse.datos[0]
                        setContractArray(dataParse.datos)
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            setIdCliente('');
            setNumContrato('')
            setNombre({ campo: '', valido: null })
            setDireccionPrincipal({ campo: '', valido: null })
            //setEstado({ campo: '', valido: null });
            setCorreo({ campo: '', valido: null });
            setTelefono({ campo: '', valido: null });
            setMovil({ campo: '', valido: null });
        }
    }
    const handleContrato = (e) => {
        setNumContrato(e)
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        const data = p[0]
        if (data) {
            setIdCliente(data.id);
            setNombre({ campo: data.nombre, valido: true })
            setDireccionPrincipal({ campo: data.direccion_principal, valido: true })
            //setEstado({ campo: data.estado, valido: true });
            setCorreo({ campo: data.correo, valido: true });
            setTelefono({ campo: data.telefono, valido: true });
            setMovil({ campo: data.movil, valido: true });
        } else {
            console.log('No hay data')
            setIdCliente('');
            setNumContrato('')
            setNombre({ campo: '', valido: null })
            setDireccionPrincipal({ campo: '', valido: null })
            //setEstado({ campo: '', valido: null });
            setCorreo({ campo: '', valido: null });
            setTelefono({ campo: '', valido: null });
            setMovil({ campo: '', valido: null });
        }
    }
    const updateClient = () => {
        setLoading(true)
        db.collection("usuarios").doc(`${idCliente}`).set({
            nombre: nombre.campo,
            correo: correo.campo,
            telefono: telefono.campo,
            movil: movil.campo,
            cedula: cedula,
            id: idCliente,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        const form = {
            user_id_microwisp: idCliente,
            nombre: nombre.campo,
            direccion: direccionPrincipal.campo,
            telefono: telefono.campo,
            celular: movil.campo,
            correo: correo.campo,
            cedula: cedula
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "contractNumbers": num_contrato,
            ...form,
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/actualizarDatos", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                setOpen(false)
                if(data.message === "Datos Guardados"){
                    Swal.fire({ icon: "success", text: "Se actualizaron los datos correctamente.", })
                }else{
                    Swal.fire({ icon: "warning", text: "Intentalo más tarde", })
                }
                console.log(data)
            }).catch((error) => console.error(error));
        /* const updateMikroClient = functions.httpsCallable('updateMikroClient')
        updateMikroClient(form)
            .then(res => {
                setLoading(false)
                setOpen(false)
                setModal(true)
                setTab(true)
                setCedula('')
                setIdCliente('');
                setNombre({ campo: '', valido: null })
                setDireccionPrincipal({ campo: '', valido: null })
                //setEstado({ campo: '', valido: null });
                setCorreo({ campo: '', valido: null });
                setTelefono({ campo: '', valido: null });
                setMovil({ campo: '', valido: null });
                console.log('Se actualizaron los datos correctamente.', res)
            })
            .catch(error => {
                console.log(error, ' error')
                Swal.fire({ icon: "info", text: error, })
            }) */
    }
    return (
        <>
            <Container maxWidth="sm">
            <Box p={5}></Box>
                <Card variant='outlined'>
                
                <img src={fondo} width='100%' alt="" /> 
                    <CardContent>
                        {tab === true ?
                            <Alert severity="success">Se ha actualizado la informacion con exito</Alert>
                            :
                            <>
                                <Typography variant='h4' component='h1'
                                    color="secondary"
                                    textAlign='center'
                                > <strong>
                                        Actualización de Datos
                                    </strong> </Typography>

                                <Box py={3}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        value={cedula}
                                        onChange={(e) => getClient(e.target.value)}
                                        label="Ingresa tu número de cédula"
                                    />
                                </Box>
                                {
                                    contractArray && cedula.length >= 10 &&
                                    <Box pb={3}>
                                        <FormControl fullWidth disabled={!contractArray}>
                                            <InputLabel>Seleccione su Linea de Contrato</InputLabel>
                                            <Select
                                                value={num_contrato}
                                                label="Seleccione su Linea de Contrato"
                                                onChange={(e) => { handleContrato(e.target.value) }}
                                            >
                                                {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                                {contractArray && contractArray.map((item, index) => (
                                                    <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                                {
                                    num_contrato &&
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                        <Grid item xs={12}>
                                            <InputValidation
                                                estado={nombre}
                                                cambiarEstado={setNombre}
                                                disabled
                                                label="NOMBRES"
                                                helperText="Solo letras y espacios"
                                                patterns={/^[a-zA-ZÀ-ÿ\s]{1,140}$/} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputValidation
                                                estado={correo}
                                                type="email"
                                                cambiarEstado={setCorreo}
                                                label="CORREO ELECTRÓNICO"
                                                helperText="Correo invalido"
                                                patterns={/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputValidation
                                                estado={telefono}
                                                cambiarEstado={setTelefono}
                                                label="TELÉFONO"
                                                type='number'
                                                helperText="minimo 10 digitos"
                                                patterns={/^\d{10}$/}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputValidation
                                                estado={movil}
                                                cambiarEstado={setMovil}
                                                label="CELULAR"

                                                type='number'
                                                helperText="minimo 10 digitos"
                                                patterns={/^\d{10}$/}
                                            />
                                        </Grid>
                                    </Grid>
                                }

                                {/* <FormControlLabel control={<Checkbox 
                                 checked={checked}
                                 onChange={() => setChecked(!checked)}
                                defaultChecked />} label={<Typography align='center' variant="caption" gutterBottom>
                                    Acepto los
                                    <Typography variant="caption"  onClick={() => setTab2(true)}>Términos y Condiciones</Typography>
                                    de Promociones Mayo “YO QUIERO A MI MAMÁ TECNOLÓGICA” DE YIGA5®
                                </Typography>} /> */}

                                <Box display="flex" pt={2} flexDirection="row-reverse">
                                    <Button
                                        fullWidth
                                        onClick={() => setOpen(true)}
                                        color='secondary'
                                        variant='contained'

                                        type="submit"
                                        disabled={!movil.campo || !telefono.campo ||
                                            movil.valido === false
                                            || telefono.valido === false}>ACTUALIZAR DATOS</Button>
                                </Box>

                            </>}
                    </CardContent>
                </Card>
            </Container>
            <Dialog maxWidth='md' open={tab2} onClose={() => setTab2(false)}>
                <DialogTitle>TÉRMINOS Y CONDICIONES</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        <Typography align='center'><strong>TÉRMINOS Y CONDICIONES PROMOCIONES MAYO “YO QUIERO A MI MAMÁ TECNOLÓGICA” DE YIGA5®</strong></Typography>
                        <Typography><strong>PARTICIPANTES</strong></Typography>
                        <Typography>Participan en el sorteo de premios todos los clientes Yiga5®: nuevos clientes que adquieran planes Yiga5® y clientes antiguos que actualicen sus datos.</Typography>
                        <Typography><strong>VIGENCIA</strong></Typography>
                        <Typography>1 al 31 de Mayo de 2023.</Typography>
                        <Typography><strong>COBERTURA / TERRITORIO</strong></Typography>
                        <Typography>Clientes de provincias de Guayas y Santa Elena donde exista servicio de internet de Yiga5®.</Typography>
                        <Typography><strong>DESCRIPCIÓN DE PREMIOS</strong></Typography>
                        <Typography>Clientes nuevos entran a sorteo de 3 laptops, 5 celulares y 5 tablets, un total de 13 premios. Clientes antiguos que actualicen datos entran a sorteo de 1 celular y 1 tablet, un total de 2 premios. El total a sortear de promociones de mayo es de 15 premios.</Typography>
                        <Typography><strong>MECÁNICA DE PROMOCIONES</strong></Typography>
                        <Typography>Entran al sorteo de premios clientes nuevos que adquieran un plan (Power Defender Plus+, Max Defender Plus+, Tendencia Defender Plus+, Influencer Defender Plus+, Épico Defender Plus+, Legendario Defender Plus+).
                            Clientes antiguos que tengan activo cualquier plan de Yiga5®, deben actualizar sus datos en la página <a href='https://24enlinea.yiga5.com/#/actualizar_datos'>https://24enlinea.yiga5.com/#/actualizar_datos</a> y completar el formulario.
                        </Typography>
                        <Typography><strong>PUBLICIDAD Y COMUNICACIÓN</strong></Typography>
                        <Typography>Yiga5® comunicará a clientes antiguos a través de medios digitales sobre actualización de datos. Para clientes nuevos la empresa contacta por sus medios: fuerza de venta, Distribuidores, canales digitales, redes sociales, call center, puntos de atención Matriz, Isla en CC Mall El Fortín. Participan los clientes que hayan sido contactados y hayan adquirido un plan de internet Yiga5® a través de cualquiera de sus canales.
                        </Typography>
                        <Typography><strong>SORTEO DE PREMIOS</strong></Typography>
                        <Typography>El sorteo se realizará a través de Facebook LIVE de la página oficial de Yiga5® https://Facebook.com/Yiga5.ec el 5 de junio de 2023.
                        </Typography>
                        <Typography><strong>EXCLUSIONES DE PARTICIPANTES</strong></Typography>
                        <Typography>No participan colaboradores de la empresa Yiga5® o sus distribuidores.
                            No participan clientes con servicio suspendido.
                            No participan clientes que no hayan completado los datos en a página de actualización.
                            No participan quienes no hayan completado la instalación y no cuenten con el servicio de internet Yiga5®.
                        </Typography>
                        <Typography><strong>AUTORIZACIÓN DE DATOS PERSONALES</strong></Typography>
                        <Typography>El cliente autoriza a Yiga® poder contactarse mediante los números celulares o correo electrónico registrados en su contratación o página de actualización de datos, para envío de información de premios, promociones o comunicaciones que la empresa requiera hacia sus usuarios de servicio; así como declara que los datos registrados son correctos y pertenecen a titular de contrato de planes de servicio de internet de Yiga5®.
                        </Typography>
                        <Typography><strong>ENTREGA DE PREMIOS</strong></Typography>
                        <Typography>Yiga5® se contactará con ganadores vía celular, según el teléfono registrado por el cliente. El lapso de retiro del premio será acorde a la ubicación de los ganadores, en oficina matriz o puntos de distribuidor si así lo acuerdan ambas partes; pero no podrá superar 1 mes posterior al sorteo, es decir, entre el 6 de junio al 5 de julio de 2023. En caso que el cliente no retire el premio en el lapso y punto acordado, Yiga5® puede hacer uso del premio para otro sorteo o promoción bajo la mecánica que estipule conveniente.
                        </Typography>
                    </DialogContentText>
                </ DialogContent>

            </Dialog>
            <Dialog maxWidth='xs' open={modal} onClose={() => setModal(false)}>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">
                            <p>¡FELICIDADES, ESTÁS PARTICIPANDO!
                                Tus datos se han guardado con éxito, sigue disfrutando de la YIGARED.
                            </p>
                        </Alert>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={open} fullWidth maxWidth="xs" >
                <DialogContent>
                    <DialogContentText>
                        ¿Esta seguro que modifica los datos correctamente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='inherit'
                        variant='contained'
                        onClick={() => setOpen(false)}
                    >Cancelar</Button>
                    <Button
                        color='success'
                        disabled={loading === true}
                        onClick={() => updateClient()}
                        variant='contained'
                        type="submit">Actualizar datos</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
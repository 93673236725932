import {
    Typography, Box, CardContent, Card, TextField,
    Grid, Stack, DialogActions, Autocomplete
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listTipoProblema, listUbicacion } from '../../../../redux/actions/parametersActions';
import { createSoporTecnico } from '../../../../redux/actions/serviciosActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../../../components/utils/InputValidation';
import Swal from 'sweetalert2'
import { BuyButtonZapping } from './BuyButtonZapping';

export const ComprarZapping = () => {

    const [cedula, setCedula] = useState('')

    return (
        <>
            <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Comprar zapping</strong></Typography>
            </Box>
            <Card>
                <CardContent>
                    <p style={{
                        fontSize: "11px",
                        marginBottom: "10px"
                    }}><strong>Digite su numero de cedula:</strong></p>
                    <TextField
                        id="outlined-basic"
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        value={cedula}
                        onChange={(e) => {
                            setCedula(e.target.value)
                        }}
                    />
                </CardContent>
            </Card>
            <Box py={2}>
                <DialogActions>
                    {/* <LoadingButton
                        size='large'
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={cedula.length < 10}
                    >
                        Continuar
                    </LoadingButton> */}
                    <BuyButtonZapping />
                </DialogActions>
            </Box>
        </>
    )
}

export default ComprarZapping;
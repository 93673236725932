import React, { useEffect, useState, useRef } from 'react'
import { functions } from '../db/firebase'
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
export const PagoAbitmediaPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [promociones, setPromociones] = useState([])
    const [modal, setModal] = useState(false)
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const gridIframe = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        handle()
    }, [dispatch])
    const handle = () => {
        setModal(true)
        setLoading(true)
        const data = {
            codigo_pago: userInfo.codigo_pago
        }
        const createUser = functions.httpsCallable('abitmedia_payment')
        createUser(data)
            .then(res => {
                //console.log('Se envia: ', res.data)
                setLoading(false)
                setData(res.data)
                console.print(res.data, 'dato')
            })
            .catch(error => {
                setLoading(false)
            })
        //
        
    }
    const handleIframe = () => {
        //console.log(document.documentElement.innerHTML, 'a')
        // let response = document.documentElement.innerHTML
        // console.log(response, 'resposne')
        // //
        // if(response.includes('Tipo de tarjeta')){
        //     console.log('Entro')
        // }
        //console.log('Hola')
        ///console.log(gridIframe.current?.innerText);
        //const iframeItem = gridIframe.current;
        //const anchors = iframeItem.contentWindow.document.getElementsByTagName("a");
        var popup = window.open(data.url, "myWindow", "width=800,height=800");
        var readyStateCheckInterval = setInterval(function() {
            console.log(popup.document.documentElement.outerHTML)
            // if (popup.document.readyState === "complete") {
            //     clearInterval(readyStateCheckInterval);
            //     popup.test();
            // }
        }, 50);
        //
        // var myWindow = window.open(data.url, "myWindow", "width=800,height=800");
        // myWindow.onload = function() {
        //     //console.log('a')
        //     //alert(myWindow.document.getElementById("result").innerHTML);
        //     //myWindow.close();
        // };
        // window.open(data.url, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
    }
    return (
        <>
            <button
                onClick={() => handleIframe()} 
            >
                Boton
            </button>
            {/* <iframe
                ref={gridIframe}
                height='600'
                width='100%'
                src={data.url}
                onLoad={handleIframe}
            /> */}
        </>
    )
}
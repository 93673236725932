import React from 'react'
import * as html2pdf from 'html2pdf.js';
import { Button } from '@mui/material';
export const pdfView = () => {
  return (
    <>
    <Button>
      a
    </Button>
    </>
  )
}

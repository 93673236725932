import { Button, Dialog, IconButton, Box, CircularProgress, Grid, DialogActions, DialogTitle } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { firebase, db } from '../../../../db/firebase'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import moment from 'moment'
import { addPointsLine } from '../../../../redux/actions/accountActions';
export const BuyButtonZapping = () => {
    const [contractArray, setContractArray] = useState([]);
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [modal, setModal] = useState(false)
    const [deuda, setDeuda] = useState("000000000499")
    const [url, setURL] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [page, setPage] = useState('')
    useEffect(() => {
        // getDeuda()
    }, [page])

    const getDeuda = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "cedula": userInfo.cedula,
            "linea": userInfo.index_linea
        });
        console.log({
            "cedula": userInfo.cedula,
            "linea": userInfo.index_linea
        })
        var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/deuda", requestOptions2)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                console.log(res, 'deuda')
                setDeuda(res.deuda)
            })
            .catch(error => console.log('error', error));
    }
    //

    const handle = () => {
        console.log(deuda, 'deuda')
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var newDeuda = deuda / 100

        var raw = JSON.stringify({
            "cedula": userInfo.cedula,
            "nombre": userInfo.nombre,
            "direccion": `${Math.floor(Math.random() * 100)} ${userInfo.direccion_principal.substring(0, 30)}`,
            "mobile": userInfo.telefono,
            "email": userInfo.email,
            "description": userInfo.servicios.perfil,
            "deuda": newDeuda
        })
        console.log({
            "cedula": userInfo.cedula,
            "nombre": userInfo.nombre,
            "direccion": `${Math.floor(Math.random() * 100)} ${userInfo.direccion_principal.substring(0, 30)}`,
            "mobile": userInfo.telefono,
            "email": userInfo.email,
            "description": 'description',
            "deuda": newDeuda
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/abitmedia", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result, ' result')
                var res = JSON.parse(result)
                setURL(res.data)
                if (res.data.url) {
                    window.location.href = res.data.url;

                    var id = `${userInfo.nombre} ${res.data.token}`
                    let date = new Date()

                    let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
                    var newDeuda = deuda / 100
                    db.collection("abitmedia_tokens").doc(id).set({
                        nombre: userInfo.nombre,
                        token: res.data.token,
                        linea: userInfo.index_linea,
                        estado: 0,
                        cedula: userInfo.cedula,
                        fecha_generada: formattedDate,
                        deuda: deuda,
                        deuda_: newDeuda,
                        created: firebase.firestore.FieldValue.serverTimestamp()
                    })
                    setLoading(false)
                }
                //dispatch(addPointsLine('Gracias por usar el boton de pago', 150))

            }).catch(error => {
                setLoading(false)
                console.log('error', error)
            });
    }
    const handleIframe = () => {
        //console.log(document.documentElement.innerHTML, 'a')
        //console.log('Se abrio')
        //let response = document.documentElement
        //console.log(response, 'resposne 32')
        //
        // if (response.includes('Ambiente seguro de pago')) {
        //     console.log('Entro')
        // }
        //console.log('Hola')
        ///console.log(gridIframe.current?.innerText);
        //const iframeItem = gridIframe.current;
        //const anchors = iframeItem.contentWindow.document.getElementsByTagName("a");
    }

    return (
        <>

            <Button
                //onClick={() => Swal.fire({ icon: 'info', text: 'Nos encontramos en actualizacion de nuestro boton de pago para darte una mejor experiencia. Continua disfrutando de nuestros demas servicios.'})}
                onClick={() => handle()}
                disabled={loading}
                variant="contained"
            >{loading ? 'Cargando...' : 'Pagar'}</Button>
            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="lg"
                open={modal}
            >
                <DialogTitle>
                    {`Pagar`}
                    {modal ? (
                        <IconButton
                            aria-label="close"
                            onClick={() => setModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}

                </DialogTitle>
                {
                    loading ?
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: '50vh' }}
                        >
                            <Grid item xs={3}>
                                <CircularProgress />
                            </Grid>

                        </Grid>
                        : <iframe
                            id="myIFrame"
                            height='900'
                            src={url.url}
                            onLoad={handleIframe}
                            //sandbox="allow-same-origin allow-scripts"
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        />
                }
            </Dialog>
        </>
    )
}

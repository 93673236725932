import React from "react";
import mantenimiento from "../src/assets/img/mantenimiento.png";

export const MantenimientoPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <img
        src={mantenimiento}
        alt="Mantenimiento"
        style={{
          maxWidth: "90vw", // 90% del ancho de la pantalla
          maxHeight: "90vh", // 90% de la altura de la pantalla
          width: "auto",
          height: "auto",
        }}
      />
    </div>
  );
};

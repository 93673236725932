
import React, { useEffect } from 'react'
import { Container, Box, CardContent, Card, Typography, Button } from '@mui/material/';
import { useDispatch, useSelector } from 'react-redux';
import pregunta from '../../assets/svg/pregunta.svg'
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
export const ClubBeneficiosPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const puntosFormateados = (userInfo?.puntos / 100).toFixed(2).replace('.', ',');
    return (
        <>
            <Container>
                <Card
                    sx={{
                        flexDirection: { xs: 'column', md: 'row' },
                        bgcolor: '#7635DC',
                        overflow: 'hidden',
                        color: 'white'
                    }}
                >
                    <CardContent>
                        <Box pl={4} pr={4} pt={2} pb={2} >
                            <div>
                                <Typography variant='h4' component='h1'><strong>Club Beneficio</strong> </Typography>
                                <Typography sx={{ mb: 1.5 }} >
                                    Su salto actual es
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Box style={{ textAlign: 'center' }}>
                                        <Typography align='center' variant='h2'>{userInfo.puntos}</Typography>
                                        <Typography align='center' variant='h5'>Yiga Coins</Typography>
                                    </Box>
                                    <Box style={{ textAlign: 'center' }}>
                                        <Typography align='center' variant='h2'>${puntosFormateados}</Typography>
                                        <Typography align='center' variant='h5'>Dolares</Typography>
                                    </Box>
                                </Box>
                            </div>
                            <Box display="flex" flexDirection="row-reverse">
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Box pt={5}></Box>
                <Card>
                    <CardContent>
                        <Box pl={4} pr={4} pt={5} pb={2} >
                            <div>
                                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                                    ¿Como funciona el YIGA CLUB?
                                </Typography>
                                <Typography variant='h6' >Si tienes un plan power y eres puntual en todas las fechas de pago
                                    podras acumular esta cantidad de yigapuntos y dolares</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Box style={{ textAlign: 'center' }}>
                                        <Typography align='center' variant='h2'>1.000</Typography>
                                        <Typography align='center' variant='body'>Yiga Puntos</Typography>
                                    </Box>
                                    <Box style={{ textAlign: 'center' }}>
                                        <Typography align='center' variant='h2'>$0.10</Typography>
                                        <Typography align='center' variant='body'>Dolares</Typography>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </CardContent>
                </Card> 
            </Container>
        </>
    )
}
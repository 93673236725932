import React from 'react';
import {
  Grid, Paper, Box, Typography, Card, CardContent
} from '@mui/material'
import zappingtv from '../../../assets/icons/zapping_tv.png'
import { Link as RouterLink } from 'react-router-dom';

export const ZappingServicePage = () => {

  return (
    <>
      <Typography variant="h3" gutterBottom><strong>Zapping</strong></Typography>
      <Box py={1}></Box>
      <Grid container spacing={5}>
        {/* <Grid item xs={6}>
          <Paper elevation={2}>
            <Box py={2} px={2}>
              <Typography sx={{ mb: 1.5 }} variant="h6">
                <strong>Comprar Zapping Tv</strong>
              </Typography>
              <Typography color="textSecondary" variant="body2">Adquiere Zapping Tv</Typography>
            </Box>
          </Paper>
        </Grid> */}
        <Grid item xs={12} lg={6} md={6}>
          <RouterLink style={{ textDecoration: 'none' }} to={''}>
            <Card id="arrow" >
              <CardContent>
                <Box py={5}>
                  <Grid container style={{ height: '100px' }}>
                    <Grid item xs={8}>
                      <Box display="flex" justifyContent="center">
                        <Box>
                          <Typography sx={{ mb: 1.5 }} variant="h6">
                            <strong>Comprar Zapping</strong>
                          </Typography>
                          <Typography color="textSecondary" variant="body2">Adquiere Zapping Tv</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex" justifyContent="center">
                        <img src={zappingtv} alt='zappingtv' width='110px' />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </RouterLink>
        </Grid>
        {/* <Grid item xs={6}>
          <Paper elevation={2}>
            <Box py={2} px={2}>
              <Typography sx={{ mb: 1.5 }} variant="h6">
                <strong>Descargar licencia</strong>
              </Typography>
              <Typography color="textSecondary" variant="body2">Descargar tu licencia de Zapping Tv</Typography>
            </Box>
          </Paper>
        </Grid> */}
        <Grid item xs={12} lg={6} md={6}>
          <RouterLink style={{ textDecoration: 'none' }} to={''}>
            <Card id="arrow" >
              <CardContent>
                <Box py={5}>
                  <Grid container style={{ height: '100px' }}>
                    <Grid item xs={8}>
                      <Box display="flex" justifyContent="center">
                        <Box>
                          <Typography sx={{ mb: 1.5 }} variant="h6">
                            <strong>Descargar licencia</strong>
                          </Typography>
                          <Typography color="textSecondary" variant="body2">Descargar tu licencia de Zapping Tv</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex" justifyContent="center">
                        <img src={zappingtv} alt='zappingtv' width='110px' />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </RouterLink>
        </Grid>
      </Grid>
    </>
  );
};

export default ZappingServicePage;
